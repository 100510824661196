.tools {
    background: #322178;
    width: 100%;
    height: 60px;
    padding: 10px 25px;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.3);

    .right {
      float: right;
    }

    .label {
      display: inline-block;
      font-size: 12px;
      font-weight: 900;
      color: rgba(#fff, 0.55);
      line-height: 40px;
      margin: 0 10px;
      float: left;
    }

    input {
      border: 1px solid rgba(#fff, 0.55);
      border-radius: 3px;
      height: 42px;
      float: left;
      padding: 13px 8px;
      box-shadow: none;
      background: #fff;
      -webkit-appearance: none;
      font-size: 11px;
      line-height: 14px;
      margin-left: 10px;
      margin-right: 10px;

      &:focus {
        outline: none;
      }
    }

    .select {
      border: 1px solid rgba(#fff, 0.55);
      width: 50px;
      border-radius: 3px;
      overflow: hidden;
      background: #fff url(../images/arrowdown.gif) no-repeat 90% 50%;
      float: left;

      select {
        padding: 13px 8px;
        width: 50px;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        font-size: 11px;
        line-height: 14px;
      }

      select:focus {
        outline: none;
      }
    }

    .button-group {
      margin-right: 10px;
      float: left;
      border-radius: 2px;
      overflow: hidden;
      border: 1px solid rgba(#fff, 0.55);
      position: relative;

      button {
        float: left;
        width: 60px;
        height: 40px;
        outline: none;
        border-top: 0;
        border-left: 0;
        border-bottom: 0;
        border-right: 1px solid rgba(#fff, 0.55);
        cursor: hand;
        cursor: pointer;

        &:last-child {
          border-right: 0;
        }

        &.active {
          background: #936DEF;
        }

      }
    }

    .save-button {
      display: inline-block;
      float: right;
      height: 42px;
      outline: none;
      background: #5A3BDA;
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 20px;
      line-height: 42px;
      border: none;
      border-radius: 5px;
      cursor: hand;
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:not(:disabled):hover {
        background: lighten(#5A3BDA, 10%);
      }
    }
  }

  #palette-wrapper {
    position: absolute;
    z-index: 10;
    top: 40px;
    left: -1px;
    width: 400px;
    height: 300px;
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.55);
    cursor: hand;
    cursor: pointer;
    display: none;

    &.show {
      display: block;
    }
  }

  #color {
    border-right: 0;
  }

  .grid-wrapper {
    display: flex;
    padding: 25px;
    position: relative;
  }

  #grid {
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }

  .preview {
    width: 150px;
    height: 150px;
    position: sticky;
    top: 25px;
    padding: 5px;
    background: transparent;
    border-radius: 3px;
  }

  .pattern-header {
    position: relative;
    width: 720px;
    margin: 20px auto;

    .pattern-header-name {
      font-size: 18px;
      font-weight: 900;
      color: rgba(#000, 0.9);
    }

    .pattern-header-user {
      font-size: 16px;
      color: rgba(#000, 0.6);
      text-decoration: none;
    }

    .pattern-header-date {
      margin-top: 4px;
      font-size: 14px;
      color: rgba(#000, 0.3);
      text-transform: uppercase;
      text-decoration: none;
    }

    .pattern-header-description:not(:empty) {
      font-size: 14px;
      margin-top: 10px;
    }
  }