@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');
@import 'constants';

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

body {
  margin: 0;
  padding: 0;
  background: #E8EDF5;
  color: #202121;
  font-family: 'Noto Sans', sans-serif;
}

*, input {
  box-sizing: border-box;
}

.app-content {
  a, a:active, a:visited {
    color: #202121;
    font-size: 13px;
  }
}

.navbar {
  position: relative;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(90deg, #5A3BDA 0%, #936DEF 100%);
  box-shadow: 0 1px 0 0 rgba(0,0,0,0.18);
  padding: 0 25px;

  .logo {
    float: left;
    margin-right: 25px;
    margin-top: 5px;
    opacity: 1 !important;
  }

  a {
    opacity: 0.55;
    float: left;
    line-height: 50px;
    margin-right: 25px;
    text-decoration: none;
    font-size: 11px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
      opacity: 1;
    }
  }

  .menu-left {
    float: left;
  }

  .menu-right {
    float: right;
  }

  .welcome {
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    margin-right: 20px;
    line-height: 50px;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 50px;
    float: left;
  }

  button {
    border: 0;
    outline: none;
    border-radius: 4px;
    background: #5A3BDA;
    line-height: 30px;
    padding: 0 15px;
    border: 0;
    outline: none;
    margin-top: 10px;
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(#000, 0.55);
    cursor: hand;
    cursor: pointer;

    &:hover {
      background: lighten(#5A3BDA, 10%);
    }
  }

  input[type=text] {
    border-radius: 4px;
    background: rgba(#fff, 0.1);
    line-height: 30px;
    padding: 0 15px;
    border: 0;
    outline: none;
    margin-top: 10px;
    color: #fff;
    width: 200px;
    float: left;

    &, &::placeholder {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: bold;
    }

    &::placeholder {
      color: rgba(#fff, 0.55);
    }

    &:focus {
      &, &::placeholder {
        color: #fff;
      }
    }
  }
}

.footer-navigation {
  display: block;
  width: 100%;
  float: none;
  clear: both;
  text-align: center;
  margin: 40px 0;

  .footer-link {
    font-size: 17px;
    font-weight: 600;

    &:hover {
      color: #666;
      font-size: 17px;
      font-weight: 600;
    }

    &:nth-child(2) {
      margin-left: 20px;
    }
  }
}

.pagination {
  .footer-link {
    &.previous:before {
      content: "\00AB";
    }

    &.next:after {
      content: " \00BB";
    }
  }
}

.show-overflow {
  overflow: visible !important;
}

@media screen and (max-width: $mobileWidth) {
  .pattern-header {
    width: 100%;
    margin: 20px 10px;
  }
}

.not-found {
  font-size: 24px;
  margin: 50px 0;
  color: #667777;
  text-align: center;
}

.footer-ads {
  display: block;
  width: 100%;
  height: 100px;
  margin: 20px 0;
}

@media screen and (max-width: 700px) {
  .navbar {
    .menu-right {
      display: none;
    }
  }
}

@media screen and (max-width: 920px) {
  .welcome {
    display: none;
  }
}

@media screen and (max-width: $mobileWidth) {
  .navbar {
    input[type=text] {
      width: calc(100% - 98px); // Room for logo
      max-width: 300px;
    }

    .menu-left, .menu-right {
      display: none;
    }
  }
}